import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Provider, useAppBridge } from '@shopify/app-bridge-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { App } from './App';

export function AppBridge() {
    const [apiKey, setApiKey] = useState(null);
    const [config, setConfig] = useState(null);

    if (apiKey == null) {
        setApiKey('');
        fetch("/auth/GetAppApiKey", {
            method: "GET",
            mode: 'same-origin',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': "application/json",
            },
            redirect: 'follow',
        }).then((response) => { response.text().then((val) => { setApiKey(val); }); });
    }

    useEffect(() => {
        if(apiKey != null && apiKey != '')
        setConfig({
            apiKey: apiKey,
            host: new URLSearchParams(location.search).get("host"),
            forceRedirect: true
        });
    }, [apiKey]);

    const navigate = useNavigate();
    const locationObj = useLocation();
    const history = useMemo(
        () => ({ replace: (path) => navigate(path, { replace: true }) }),
        [navigate],
    );

    const router = useMemo(
        () => ({
            locationObj,
            history,
        }),
        [locationObj, history],
    );

    return (
        <div>
            {apiKey && config &&
                <Provider config={config} router={router}>
                    <App />
                </Provider>
            }
        </div>
    )
}
