import { Redirect, Loading } from '@shopify/app-bridge/actions';
import { getSessionToken } from "@shopify/app-bridge/utilities";

export function RequestSubscription(plan, buttonLoadingSetter, setError, app) {
    SetButtonLoading(buttonLoadingSetter, true);
    callAPI(app, `/Subscription/HandleStartSubscription?plan=${plan}&host=${new URLSearchParams(location.search).get("host")}`, 'POST', 'application/json')
        .then(response => response.text())
        .then(response => {
            const redirect = Redirect.create(app);
            if (response != '') {
                redirect.dispatch(Redirect.Action.REMOTE, {
                    url: response,
                    newContext: false,
                });
            }
            else {
                OpenSettings(app);
            }
            console.log(response);
        })
        .catch(error => {
            setError(error);
            SetButtonLoading(buttonLoadingSetter, false);
        });
}

export function SubmitSupportRequest(email, description, buttonLoadingSetter, setError, setSupportRequestSubmitted, app) {
    SetButtonLoading(buttonLoadingSetter, true);
    callAPI(app, `/Subscription/SubmitSupportRequest?email=${email}`, 'POST', 'application/json', description)
        .then(response => response.text())
        .then(response => {
            const redirect = Redirect.create(app);
            redirect.dispatch(Redirect.Action.REMOTE, {
                url: response,
                newContext: true,
            });
            SetButtonLoading(buttonLoadingSetter, false);
            setSupportRequestSubmitted(true);
            console.log(response);
        })
        .catch(error => {
            setError("Deploying Webchat has failed.");
            console.error(error);
            SetButtonLoading(buttonLoadingSetter, false);
        });
}

export function DeepLinkToTheme(buttonLoadingSetter, setError, app) {
    SetButtonLoading(buttonLoadingSetter, true);
    callAPI(app, `/Subscription/DeployWebchatLink`, 'GET', 'application/json')
        .then(response => response.text())
        .then(response => {
            const redirect = Redirect.create(app);
            SetButtonLoading(buttonLoadingSetter, false);
            redirect.dispatch(Redirect.Action.ADMIN_PATH, response);
        })
        .catch(error => {
            setError("Getting direct deployment link failed. Go to templates to activate webchat.");
            console.error(error);
            SetButtonLoading(buttonLoadingSetter, false);
        });
}

export function GetSubscriptionDetails(app) {
    return callAPI(app, '/Subscription/GetSubscriptionDetails', 'GET', 'application/text');
}

export async function CheckIfInstanceIsProvisioned(app, intervalCheck, setChatlakeURL) {
    var instanceSSORequest = await GetSubscriptionSSOLink(app);
    var instanceSSOLink = await instanceSSORequest.text();
    if (instanceSSOLink !== null && instanceSSOLink != '') {
        clearInterval(intervalCheck);
        setChatlakeURL(instanceSSOLink);
    }
}

export function GetSubscriptionSSOLink(app) {
    return callAPI(app, '/Subscription/GetSubscriptionSSOLink', 'GET', 'application/text');
}

export function ChargeActivation(app, chargeId, host) {
    return callAPI(app, `/Subscription/ChargeResult?charge_id=${chargeId}&host=${host}`, 'GET', 'application/text');
}

async function callAPI(app, url, method, contentType, data = null) {
    // Default options are marked with *
    var response = null;
    const sessionToken = await getSessionToken(app);
    if (data == null) {
        response = await fetch(url, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': contentType,
                'Authorization': `Bearer ${sessionToken}`
            },
            redirect: 'follow', // manual, *follow, error
            //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        });
    }
    else {
        response = await fetch(url, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': contentType,
                'Authorization': `Bearer ${sessionToken}`
            },
            redirect: 'follow', // manual, *follow, error
            //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
    }

    if (!response.ok) {
        const error = await response.text();
        throw error;
    }
    return response;
}

export function IsIFramed() {
    return window.self !== window.top;
}

export function OpenMain(app) {
    const redirect = Redirect.create(app);
    const loading = Loading.create(app);
    loading.dispatch(Loading.Action.START);
    redirect.dispatch(Redirect.Action.APP, '/');
}

export function OpenPlanSelection(app) {
    const redirect = Redirect.create(app);
    const loading = Loading.create(app);
    loading.dispatch(Loading.Action.START);
    redirect.dispatch(Redirect.Action.APP, '/planselection');
}

export function OpenSettings(app) {
    const redirect = Redirect.create(app);
    const loading = Loading.create(app);
    loading.dispatch(Loading.Action.START);
    redirect.dispatch(Redirect.Action.APP, '/settings');
}

export function OpenChatlake(app) {
    const redirect = Redirect.create(app);
    const loading = Loading.create(app);

    GetSubscriptionSSOLink(app).then(response => response.text())
        .then(response => {
            redirect.dispatch(Redirect.Action.REMOTE, {
                url: response,
                newContext: true,
            });
        });
}

export function SetButtonLoading(setter, loading) {
    setter(loading);
}

export function InitiateLoading(setLoadingPage, app) {
    const loading = Loading.create(app);

    loading.subscribe(Loading.Action.START, () => {
        setLoadingPage(true);
    });
    loading.subscribe(Loading.Action.STOP, () => {
        setLoadingPage(false);
    });
}