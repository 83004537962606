import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import "@shopify/polaris/build/esm/styles.css";
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { BrowserRouter  } from 'react-router-dom';

import { AppBridge } from './AppBridge';




function WrappedApp() {
    return (
        <AppProvider i18n={enTranslations}>
            <BrowserRouter>
                <AppBridge />
            </BrowserRouter>
        </AppProvider>
    );
}

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(<WrappedApp />);