import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Provider, useAppBridge } from '@shopify/app-bridge-react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
    Page,
    Badge,
    Link,
    Banner,
    List,
    FooterHelp,
    Tabs,
    Layout,
    Frame,
    Loading,
    DisplayText,
    Popover,
    ProgressBar,
    ActionList,
    Heading,
    FormLayout,
    TextField,
    Checkbox,
    Button,
    Icon,
    Stack,
    Card,
    Spinner,
    DescriptionList

} from '@shopify/polaris';
import { CircleTickMajor, CircleCancelMajor, MaximizeMajor } from '@shopify/polaris-icons';
import {
    GetSubscriptionDetails, RequestSubscription, CheckIfInstanceIsProvisioned,
    SubmitSupportRequest, DeepLinkToTheme, OpenPlanSelection, OpenSettings, OpenChatlake, OpenMain,
    InitiateLoading, ChargeActivation
} from './Helpers';
import { ExitIframe } from './ExitIframe';

export function App() {
    const [Email, setEmail] = useState(null);
    const [Error, setError] = useState(null);
    const [CurrentPlan, setCurrentPlan] = useState(null);
    const [ChatlakeURL, setChatlakeURL] = useState(null);
    const [DBStorage, setDBStorage] = useState(null);
    const [AttachmentsStorage, setAttachmentsStorage] = useState(null);
    const [ShopifyDevelopmentPlan, setShopifyDevelopmentPlan] = useState(null);
    const [ReactivationNeededBy, setReactivationNeededBy] = useState(null);
    const [TrialEntitled, setTrialEntitled] = useState(null);
    const [LoadingPage, setLoadingPage] = useState(false);
    const appContext = useAppBridge();

    useEffect(() => {
        GetSubscriptionDetails(appContext)
            .then(response => response.json())
            .then(subscription => {
                setEmail(subscription.email);
                setCurrentPlan(subscription.plan);
                setDBStorage(subscription.dbStorageConsumed.toFixed(2));
                setAttachmentsStorage(subscription.attachmentsStorageConsumed.toFixed(2));
                setShopifyDevelopmentPlan(subscription.shopifyDevelopmentPlan);
                setReactivationNeededBy(subscription.reactivationNeededBy);
                setTrialEntitled(subscription.trialEntitled);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    InitiateLoading(setLoadingPage, useAppBridge());


    return (
        <Frame>
            {LoadingPage && (<Loading />)}
            <Page fullWidth>
                <Page>
                    {Error != null && <ErrorBanner error={Error} onDismiss={(error) => setError(null)} />}
                    {(ReactivationNeededBy != null && CurrentPlan != null && CurrentPlan != -1) && <ReactivationNeededBanner error={`Your subscription needs to be activated by ${ReactivationNeededBy} to avoid loosing data`} CurrentPlan={CurrentPlan} errorHandler={setError} />}
                </Page>
                <Routes>
                    <Route path="/settings" element={<Settings chatlakeURL={ChatlakeURL} setChatlakeURL={setChatlakeURL} Email={Email} errorHandler={setError} DBStorage={DBStorage} AttachmentsStorage={AttachmentsStorage} />}>
                    </Route>
                    <Route path="/paymentsuccess" element={<PaymentSuccess />}>
                    </Route>
                    <Route path="/paymentdeclined" element={<PaymentDeclined />}>
                    </Route>
                    <Route path="/planselection" element={<SelectPlan CurrentPlan={CurrentPlan} TrialEntitled={TrialEntitled} ShopifyDevelopmentPlan={ShopifyDevelopmentPlan} ReactivationNeededBy={ReactivationNeededBy} errorHandler={setError} />}>
                    </Route>
                    <Route path="/exitiframe" element={<ExitIframe />}>
                    </Route>
                    <Route path="/" element={<Main CurrentPlan={CurrentPlan} />}>
                    </Route>
                </Routes>
                <FooterHelp>
                    Need help?{' '}
                    <Link external url="mailto:support@chatlake.app">
                        Contact us!
                    </Link>
                </FooterHelp>
            </Page>
        </Frame>
    );
}

export function Main(props) {
    const app = useAppBridge();
    //const { search } = useLocation();

    useEffect(() => {
        if (props.CurrentPlan == -1) {
            OpenPlanSelection(app);
        }
        else if (props.CurrentPlan != null && props.CurrentPlan != -1) {
            OpenSettings(app);
        }
    }, [props.CurrentPlan]);

    return null;
}


export function SelectPlan(props) {
    const plans = [
        {
            id: 0,
            name: "Free",
            monthlyPrice: 0.00,
            recommended: "Great to start with",
            description: (
                <div>
                    <PlanCardDescriptionLine Description="0.5GB data storage limit" IsSuccess={true} />
                    <PlanCardDescriptionLine Description="1GB file storage limit" IsSuccess={true} />
                    <PlanCardDescriptionLine Description="Unlimited conversations" IsSuccess={true} />
                    <PlanCardDescriptionLine Description="Unlimited integrations" IsSuccess={true} />
                    <PlanCardDescriptionLine Description="Not whitelabelled" IsSuccess={false} />
                    <PlanCardDescriptionLine Description="No priority support" IsSuccess={false} />
                </div>
            )
        },
        {
            id: 1,
            name: "Starter",
            monthlyPrice: 9.99,
            recommended: "Occasional use",
            description: (
                <div>
                    <PlanCardDescriptionLine Description="1GB data storage limit" IsSuccess={true} />
                    <PlanCardDescriptionLine Description="2GB file storage limit" IsSuccess={true} />
                    <PlanCardDescriptionLine Description="Unlimited conversations" IsSuccess={true} />
                    <PlanCardDescriptionLine Description="Unlimited integrations" IsSuccess={true} />
                    <PlanCardDescriptionLine Description="Not whitelabelled" IsSuccess={false} />
                    <PlanCardDescriptionLine Description="No priority support" IsSuccess={false} />
                </div>
            )
        },
        {
            id: 2,
            name: "Plus",
            monthlyPrice: 19.99,
            recommended: "Optimal choice",
            description: (
                <div>
                    <PlanCardDescriptionLine Description="5GB data storage limit" IsSuccess={true} />
                    <PlanCardDescriptionLine Description="10GB file storage limit" IsSuccess={true} />
                    <PlanCardDescriptionLine Description="Unlimited conversations" IsSuccess={true} />
                    <PlanCardDescriptionLine Description="Unlimited integrations" IsSuccess={true} />
                    <PlanCardDescriptionLine Description="Not whitelabelled" IsSuccess={false} />
                    <PlanCardDescriptionLine Description="No priority support" IsSuccess={false} />
                </div>
            )
        },
        {
            id: 3,
            name: "Premium",
            monthlyPrice: 99.99,
            recommended: "Has it all",
            description: (
                <div>
                    <PlanCardDescriptionLine Description="25GB data storage limit" IsSuccess={true} />
                    <PlanCardDescriptionLine Description="100GB file storage limit" IsSuccess={true} />
                    <PlanCardDescriptionLine Description="Unlimited conversations" IsSuccess={true} />
                    <PlanCardDescriptionLine Description="Unlimited integrations" IsSuccess={true} />
                    <PlanCardDescriptionLine Description="Whitelabelled" IsSuccess={true} />
                    <PlanCardDescriptionLine Description="Priority email support" IsSuccess={true} />
                </div>
            )
        },
    ];

    const app = useAppBridge();
    return (
        <Page fullWidth title="Plan selection">
            <Layout>
                {props.ShopifyDevelopmentPlan &&
                    <Banner title="Chatlake is partner friendly!" status="info">
                        <p>You will not be charged for development stores. When you upgrade to standard store you will be asked to upgrade Chatlake to paid or free plan</p>
                    </Banner>
                }
            </Layout>
            <div style={{ paddingTop: 15 }}>
                <Layout>
                    {plans.map(plan => (
                        <PlanCard
                            key={plan.id}
                            isSelected={plan.id === props.CurrentPlan}
                            planId={plan.id}
                            title={plan.name}
                            monthlyPrice={plan.monthlyPrice}
                            description={plan.description}
                            recommended={plan.recommended}
                            errorHandler={props.errorHandler}
                            CurrentPlan={props.CurrentPlan}
                            ShopifyDevelopmentPlan={props.ShopifyDevelopmentPlan}
                            ReactivationNeededBy={props.ReactivationNeededBy}
                            TrialEntitled={props.TrialEntitled}
                        />
                    ))}
                </Layout>
            </div>
            <div style={{ paddingTop: 15, textAlign: 'center' }}>
                <Layout>
                    <Layout.Section>
                        {props.CurrentPlan != -1 && props.CurrentPlan != null && props.ReactivationNeededBy == null && (
                            <Button onClick={() => { OpenMain(app); }}
                            >
                                or continue with current plan
                            </Button>
                        )}
                    </Layout.Section>
                </Layout>
            </div>
        </Page>
    );
}

export function Settings(props) {
    const [publishButtonLoading, setPublishButtonLoading] = useState(null);
    const [supportRequestDescription, setSupportRequestDescription] = useState(null);
    const [supportRequestContactEmail, setSupportRequestContactEmail] = useState(null);
    const [supportRequestSubmitButtonLoading, setSupportRequestSubmitButtonLoading] = useState(null);
    const [supportRequestSubmitted, setSupportRequestSubmitted] = useState(false);


    useEffect(() => {
        if (supportRequestSubmitted === true) {
            setSupportRequestContactEmail(null);
            setSupportRequestDescription(null);
        }
    }, [supportRequestSubmitted]);

    useEffect(() => {
        if (props.Email !== null) {
            setSupportRequestContactEmail(props.Email);
        }
    }, [props.Email]);

    const app = useAppBridge();

    //loop request checking if instance is provisioned
    useEffect(() => {
        const intervalCheckIfInstanceProvisioned = setInterval(() => CheckIfInstanceIsProvisioned(app, intervalCheckIfInstanceProvisioned, props.setChatlakeURL), 1000);//every second
        return () => clearInterval(intervalCheckIfInstanceProvisioned);
    }, []);

    return (
        <Layout>
            <Layout.AnnotatedSection
                title="Open your Chatlake instance"
                description="Click it to open Chatlake instance as admin."
            >
                <Card sectioned>
                    <FormLayout>
                        <Button primary onClick={() => OpenChatlake(app)} loading={props.chatlakeURL == '' || props.chatlakeURL == null} icon={MaximizeMajor}>Open as Admin</Button>
                    </FormLayout>
                </Card>
            </Layout.AnnotatedSection>

            <Layout.AnnotatedSection
                title="Publish Live Chat in the store front"
                description="Click it to open theme settings where you can activate Live Chat feature in your store front. You can do the same by manually deploying script to your store HTML pages, but this method is quicker and easier to rollback."
            >
                <Card sectioned>
                    <FormLayout>
                        <Button primary loading={publishButtonLoading} onClick={() => DeepLinkToTheme(setPublishButtonLoading, props.errorHandler, app)}>Publish Live Chat</Button>
                    </FormLayout>
                </Card>
            </Layout.AnnotatedSection>

            <Layout.AnnotatedSection
                title="Storage consumption"
                description="Check how much available storage you use"
            >
                <Card sectioned>
                    <FormLayout>
                        <div style={{ width: 225 }}>
                            <ProgressBar progress={props.DBStorage} color="primary" />
                            <p>Data storage: <b>{props.DBStorage}%</b></p>
                            <br />
                            <ProgressBar progress={props.AttachmentsStorage} color="success" />
                            <p>File storage: <b>{props.AttachmentsStorage}%</b></p>
                        </div>
                    </FormLayout>
                </Card>
            </Layout.AnnotatedSection>
            {/*//TODO: this one to be uncommented to roll back paid plans selection*/}
            {/*<Layout.AnnotatedSection*/}
            {/*    title="Change your subscription plan"*/}
            {/*    description="Click on the Change button to change your subscription plan. "*/}
            {/*>*/}
            {/*    <Card sectioned>*/}
            {/*        <FormLayout>*/}
            {/*            <Button onClick={() => OpenPlanSelection(app)}>Change</Button>*/}
            {/*        </FormLayout>*/}
            {/*    </Card>*/}
            {/*</Layout.AnnotatedSection>*/}

            <Layout.AnnotatedSection
                title="Open support request"
                description="If you have any issues with the platform, open Support Request and we will contact you."
            >
                <Card sectioned>
                    {supportRequestSubmitted &&
                        (
                            <Banner key="supportRequestConfirmation" status="success" onDismiss={() => { setSupportRequestSubmitted(false) }}>
                                <p>
                                    Your request has been submitted!
                                </p>
                            </Banner>
                        )
                    }
                    <FormLayout>
                        <TextField
                            label="Contact Email"
                            type="email"
                            value={supportRequestContactEmail}
                            onChange={(value) => setSupportRequestContactEmail(value)}
                            autoComplete="off"
                        />
                        <TextField
                            label="Problem description"
                            placeholder="Tell us what is the problem"
                            value={supportRequestDescription}
                            onChange={(value) => setSupportRequestDescription(value)}
                            multiline={4}
                            autoComplete="off"
                        />
                        <Button primary loading={supportRequestSubmitButtonLoading} onClick={() => SubmitSupportRequest(supportRequestContactEmail, supportRequestDescription, setSupportRequestSubmitButtonLoading, props.errorHandler, setSupportRequestSubmitted, app)}>Submit</Button>
                    </FormLayout>
                </Card>
            </Layout.AnnotatedSection>
        </Layout>);
}


function ErrorBanner(props) {
    return (
        <Banner key="globalError" status="critical" onDismiss={props.onDismiss}>
            <p>
                {props.error}
            </p>
        </Banner>
    );
}

function ReactivationNeededBanner(props) {
    const [selectButtonLoading, setSelectButtonLoading] = useState(null);
    const app = useAppBridge();
    return (
        <Banner key="globalError" status="critical" title={props.error}
            action={{ content: 'Reactivate subscription', disabled: selectButtonLoading, onAction: () => RequestSubscription(props.CurrentPlan, setSelectButtonLoading, props.errorHandler, app) }}
            //TODO: one below to be uncommented when paid plans are added back
            //secondaryAction={{
            //    content: 'Choose plan', onAction: () => OpenPlanSelection(app)
            //}}
        />
    );
}

function PaymentDeclined(props) {
    return (
        <Banner key="paymentDeclined" status="critical">
            <p>
                Your subscription has been declined. It's a good idea to initiate your subscription again.
            </p>
        </Banner>
    );
}

function PaymentSuccess(props) {
    const app = useAppBridge();
    var chargeId = new URLSearchParams(location.search).get("charge_id");
    var host = new URLSearchParams(location.search).get("host");
    ChargeActivation(app, chargeId, host);
    OpenMain(app);
    return (<div />);
}

function PlanCard(props) {
    const [selectButtonLoading, setSelectButtonLoading] = useState(null);
    const {
        planId,
        isSelected,
        title,
        monthlyPrice,
        recommended,
        description,
        setError,
        CurrentPlan,
        ShopifyDevelopmentPlan,
        ReactivationNeededBy,
        TrialEntitled
    } = props;
    const app = useAppBridge();
    return (
        <Layout.Section key={`key_${planId}`} secondary>
            {/*<div style={{ textAlign: 'center', marginBottom: '-3%' }}>*/}
            {/*    <Badge status="warning">{recommended}</Badge>*/}
            {/*</div>*/}
            <Card title={title} sectioned>
                <div>
                    <div>
                        <DisplayText size="large">${monthlyPrice}/Month</DisplayText>
                        <div style={{ marginTop: 12, marginBottom: 12 }}>
                            {description}
                        </div>
                        <div />
                    </div>

                    <div>
                        <Button
                            onClick={() => RequestSubscription(planId, setSelectButtonLoading, setError, app)}
                            loading={selectButtonLoading}
                            primary
                            fullWidth={true}
                            disabled={isSelected && !ReactivationNeededBy}
                            size="large"
                        >
                            {isSelected && ReactivationNeededBy == null && "Current Plan"}
                            {!isSelected && ReactivationNeededBy == null && (!TrialEntitled || ShopifyDevelopmentPlan ? "Select" : "Start 14 day Trial!")}
                            {ReactivationNeededBy != null && "Select"}
                        </Button>
                    </div>
                </div>
            </Card>
        </Layout.Section>
    );
}

function PlanCardDescriptionLine(props) {
    const {
        IsSuccess,
        Description
    } = props;
    return (
        <Layout.Section>
            <Stack>
                <Stack.Item>
                    {
                        IsSuccess == true && (<Icon
                            source={CircleTickMajor}
                            color="success"
                        />)
                    }

                    {
                        IsSuccess == false && (<Icon
                            source={CircleCancelMajor}
                            color="critical"
                        />)
                    }

                </Stack.Item>
                <Stack.Item>
                    <p>{Description}</p>
                </Stack.Item>
            </Stack>
        </Layout.Section>
    );
}